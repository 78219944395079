<template>
  <div class="travel_type-list" :key="key">
    <div class="text-h5 font-weight-bold mb-5">Traveler types</div>
    <draggable :list="data" @change="changeOrder($event)" handle=".handle">
      <div v-for="item in data" :key="item.id" class="d-flex align-center mt-3">
        <img class="move-icon handle me-3" src="@/assets/icon/move.svg" alt="icon" />
        <v-card outlined flat class="width100 rounded-lg pt-4 px-5 d-flex" min-height="201">
          <div class="width100">
            <div class="d-flex flex-md-row flex-column multi-direct">
              <v-text-field
                class="field46 width100 me-md-3"
                v-model="item.title_en"
                placeholder="Title"
                :error-messages="titleEnErrors"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
              <v-text-field
                class="field46 width100 mt-md-0 mt-n2 ms-md-3"
                v-model="item.title_ar"
                placeholder="عنوان"
                :error-messages="titleArErrors"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </div>
            <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
              <v-textarea
                class="width100 me-md-3"
                v-model="item.description_en"
                placeholder="Description"
                :error-messages="desEnErrors"
                auto-grow
                rows="3"
                row-height="30"
                outlined
                dense
                required
                color="primary"
              ></v-textarea>
              <v-textarea
                class="width100 mt-md-0 mt-n2 ms-md-3"
                v-model="item.description_ar"
                placeholder="وصف كامل"
                :error-messages="desArErrors"
                auto-grow
                rows="3"
                row-height="30"
                outlined
                dense
                required
                color="primary"
              ></v-textarea>
            </div>
          </div>
          <v-img
            class="rounded-lg mx-10 card-img link d-flex align-center justify-center"
            @click="changeImage(item)"
            max-width="100"
            height="100"
            cover
            v-if="item.image || item.picture"
            :src="item.image || item.picture"
          >
            <div class="block text-center mx-auto">
              <img width="36" src="@/assets/icon/upload.svg" alt="icon" />
              <div class="text-body-2 white--text">Replace<br />Image</div>
            </div>
          </v-img>
          <v-card
            class="rounded-lg mx-10 link width100 gray d-flex align-center justify-center"
            @click="changeImage(item)"
            max-width="100"
            height="100"
            v-else
            outlined
            :src="item.image"
          >
            <div class="text-center">
              <img width="36" src="@/assets/icon/upload.svg" alt="icon" />
              <div class="text-body-2 white--text">Replace<br />Image</div>
            </div>
          </v-card>
          <div class="width100">
            <v-btn @click="update(item)" depressed outlined color="primary" class="rounded mr-2" width="100" height="38">
              <v-icon color="primary">mdi-check</v-icon><span class="text-body-2">Save</span>
            </v-btn>
            <v-btn @click="openRemove(item)" depressed outlined color="error" class="rounded" width="48" height="38" icon>
              <img class="error-filter" src="@/assets/icon/delete.svg" alt="icon" />
            </v-btn>
          </div>
        </v-card>
      </div>
    </draggable>
    <v-btn @click="modal = true" depressed class="primary rounded mt-5 ml-6" width="136" height="30">
      <span class="text-body-2">Add new</span>
    </v-btn>
    <v-dialog v-model="modal" max-width="700">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">New traveler type</div>
        <v-card flat outlined class="px-10 py-3">
          <v-card flat height="290" width="290" class="mx-auto gray darken-5 d-flex align-center justify-center rounded">
            <div class="white--text text-center link" @click="changeImage('')" v-if="!image">
              <img src="@/assets/icon/upload.svg" alt="icon" />
              <div class="font-weight-bold">Upload image</div>
              <div class="text-body-2">.jpeg</div>
            </div>
            <div v-else class="rounded width100 link" @click="changeImage('')">
              <img class="bg-picture width100 d-block rounded" :src="image" />
              <div class="bg-picture-btn white--text text-center align-center justify-center">
                <div>
                  <img src="@/assets/icon/upload.svg" alt="icon" />
                  <div class="font-weight-bold">Upload image</div>
                  <div class="text-body-2 mb-3">.jpeg</div>
                </div>
              </div>
            </div>
          </v-card>
          <div class="my-5">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                class="field46"
                v-model="modalData.title_en"
                :error-messages="titleEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                dir="rtl"
                class="field46"
                :error-messages="titleArErrors"
                v-model="modalData.title_ar"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                v-model="modalData.description_en"
                :error-messages="desEnErrors"
                placeholder="Enter description (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="30"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="desArErrors"
                v-model="modalData.description_ar"
                placeholder="Enter description (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="30"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="createItem" depressed class="primary rounded text-body-2" width="106" height="30">Done</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalRemove" max-width="480">
      <v-card flat color="pa-4">
        <div class="text-h6 font-weight-bold">Do you really want to remove {{ modalData.title_en }}?</div>
        <div class="text-end mb-1 mt-5">
          <v-btn @click="modalRemove = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="removeItem" depressed class="error rounded text-body-2" width="106" height="30">Remove</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalRemove: false,
      modalData: {},
      image: '',
      file: '',
      key: 1,
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Traveler type item was successfully update',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'photo') {
        this.$notify({
          title: 'Done!',
          message: 'Traveler type picture item was successfully update',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    changeImage(i) {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          i ? (i.file = this.files[0]) : (self.file = this.files[0]);
          var reader = new FileReader();
          reader.onload = (e) => {
            i ? (i.image = e.target.result) : (self.image = e.target.result);
            i ? self.updatePicture(i) : '';
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    openRemove(i) {
      this.modalRemove = true;
      this.$nextTick(() => {
        this.modalData = Object.assign({}, i);
      });
    },
    async changeOrder(e) {
      this.error = [];
      const data = new Object();
      data.order = e.moved.newIndex;
      await this.$store.dispatch('updateTripTravelTypesItemOrder', { id: e.moved.element.id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    async getData() {
      await this.$store.dispatch('getTripTravelTypes').catch((e) => {
        this.getDataError(e);
      });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async createItem() {
      this.error = [];
      const formData = new FormData();
      formData.append('picture', this.file);
      this.modalData.title_en ? formData.append('title_en', this.modalData.title_en) : '';
      this.modalData.title_ar ? formData.append('title_ar', this.modalData.title_ar) : '';
      this.modalData.description_en ? formData.append('description_en', this.modalData.description_en) : '';
      this.modalData.description_ar ? formData.append('description_ar', this.modalData.description_ar) : '';
      await this.$store
        .dispatch('createTripTravelTypes', formData)
        .then((res) => {
          this.modal = false;
          this.modalData = {};
          this.image = '';
          this.file = '';
          this.data.push(res.data);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async update(item) {
      this.error = [];
      const data = new Object();
      data.title_en = item.title_en || '';
      data.title_ar = item.title_ar || '';
      data.description_en = item.description_en || '';
      data.description_ar = item.description_ar || '';
      await this.$store
        .dispatch('updateTripTravelTypesItem', { id: item.id, data: data })
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async updatePicture(item) {
      this.error = [];
      const formData = new FormData();
      formData.append('picture', item.file);
      await this.$store
        .dispatch('updateTripTravelTypesItemPicture', { id: item.id, data: formData })
        .then(() => {
          this.notifi('photo');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async removeItem() {
      this.error = [];
      await this.$store
        .dispatch('removeTripTravelTypesItem', this.modalData.id)
        .then(() => {
          const idx = this.data.findIndex((i) => i.id == this.modalData.id);
          this.modalRemove = false;
          this.data.splice(idx, 1);
          ++this.key;
          this.modalData = {};
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = {};
      this.image = '';
      this.file = '';
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripTvTypes;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripTravelTypes');
  },
};
</script>

<style lang="scss">
.bg-picture {
  object-fit: cover;
}
.bg-picture-btn {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 290px;
  background: rgba($color: #3d3d3d, $alpha: 0.6);
  z-index: 9;
}
.rounded:hover {
  .bg-picture-btn {
    display: flex;
  }
}
#app .travel_type-list {
  .v-input {
    max-width: 335px;
  }
  .block {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: rgba($color: #3d3d3d, $alpha: 0.6);
  }
  .card-img:hover {
    .block {
      padding-top: 8px;
      display: block;
    }
  }
  .v-btn.primary--text {
    background: var(--v-primary-lighten3);
  }
  .v-btn.error--text {
    background: var(--v-error-lighten5);
  }
}
</style>
